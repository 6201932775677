<template>
  <Card
    :img="img"
    :to="to"
    class="category"
    no-shadow
    >

    <template v-slot:title>
      <div class="mb-0 card-header-wrapper" :style="{ '--progress': `${progress}%`}">
        <div class="card-title-wrapper">
          <h4>{{ title }}</h4>
        </div>
      </div>
    </template>

    <template v-slot:content>
      <!-- <div class="paragraph">
        {{ text }}
      </div> -->

      <!-- <b-button :to="to" variant="secondary" class="mt-4" block>{{ btnText }}</b-button> -->
    </template>
  </Card>
</template>

<script>
export default {
  name: 'CategoryCard',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    progress: {
      type: Number,
      required: false,
      default: 0
    },
    img: {
      type: String,
      required: false,
      default: ''
    },
    to: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  computed: {
    btnText () {
      return this.$t(`ACTIONS.${this.progress > 0 ? 'continue' : 'start'}`)
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {

    .card-header-wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;

      --progress: 30%;

      &:before {
        content: '';
        display: block;
        height: 8px;
        width: 100%;
        background: linear-gradient(90deg, $green-dark var(--progress), #E3E3E3 var(--progress));
        position: absolute;
      }
    }
    .card-title-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 1rem;
      width: 100%;

      h4 {
        font-size: 1.5rem;
      }
    }

    .progress-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 25%;
      margin-left: auto;
      text-align: right;
    }
    .card-footer {
      border-top: none;
    }
  }
</style>
