import i18nservice from '@/services/i18n.service'

export default {
  methods: {
    getTranslatedValue (obj, field) {
      if (typeof obj === 'undefined') return ''
      if (typeof obj.translates === 'undefined') return obj[field]
      if (typeof obj.translates[field] === 'undefined') return obj[field]
      if (typeof obj.translates[field][i18nservice.getActiveLanguage()] === 'undefined') return obj[field]
      if (obj.translates[field][i18nservice.getActiveLanguage()] === '') return obj[field]

      return obj.translates[field][i18nservice.getActiveLanguage()]
    },
    translateObjectArray (arr) {
      if (typeof arr === 'undefined') return []

      return arr.map(item => {
        return this.translateObject(item)
      })
    },
    translateObject (obj) {
      var or = {}
      Object.keys(obj).filter(i => i !== 'translates').map(key => { or[key] = this.getTranslatedValue(obj, key) })
      return or
    }
  }
}
