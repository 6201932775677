<template>
  <div class="home">
    <loading :loading="isLoading"></loading>
    <SectionHeader
    :name="$t('PAGES.careers')"
    description=""
    :progress="0"
    />
    <div class="main-container px-md-5 py-5">
      <b-row>
        <b-col class="mb-3" sm="12" md="6" lg="4" xl="3"
        v-for="content in translatedCareers"
        v-bind:key="content.id">
          <CategoryCard
            :img="getUrlImg(content.image)"
            :title="content.name"
            :text="content.description"
            :progress="calculateProgress(content.score, content.total_points)"
            class="category"
            :to="{ name: 'careerDetail', params: { id: content.id, name: content.title } }"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CategoryCard from '@/components/utils/cards/CategoryCard'
import SectionHeader from '@/components/SectionHeader'

import mapper from '@/services/mixins/mapper.mixins'
import ProgressMixin from '@/services/mixins/progress.mixins'
import i18nmixin from '@/services/mixins/i18n.mixins'

export default {
  name: 'Careers',
  mixins: [mapper, ProgressMixin, i18nmixin],
  components: { CategoryCard, SectionHeader },
  data () {
    return { }
  },
  methods: {
    ...mapActions({
      getCareers: 'careers/getCareers'
    })
  },
  computed: {
    ...mapGetters({
      careers: 'careers/getCareers',
      isLoading: 'careers/isLoading'
    }),

    translatedCareers () { return this.translateObjectArray(this.careers) }
  },
  mounted () {
    this.getCareers()
  }
}
</script>
