import URLS from '@/config/urls'
import { PILL_STATUS, LAB_MACHINE_STATUS } from '@/config/constants'

export default {
  methods: {
    getDifficultyDisplayName (data) {
      if (typeof data === 'undefined') return ''
      return this.$t(`PILLS.DIFFICULTIES.${data}`)
    },
    getPillTypeDisplayName (data) {
      if (typeof data === 'undefined') return ''
      return this.$t(`PILLS.TYPES.${data}`)
    },
    getPillStatusDisplayName (data) {
      if (typeof data === 'undefined') return ''
      if (data === '') return this.$t(`PILLS.STATUS.${PILL_STATUS.PENDING}`)
      return this.$t(`PILLS.STATUS.${data}`)
    },
    getLabStatusDisplayName (data) {
      if (typeof data === 'undefined') return ''
      if (data === '') return this.$t(`LABS.STATUS.${LAB_MACHINE_STATUS.PENDING}`)

      return this.$t(`LABS.STATUS.${data}`)
    },
    getUrlImg (name) { return URLS.API.STATIC.GET_IMG(name) }
  }
}
