import { PILL_STATUS } from '@/config/constants'

export default {
  methods: {
    calculateProgress (score, total) {
      if (typeof score === 'string') score = parseInt(score)
      if (typeof total === 'string') total = parseInt(total)

      if (typeof score === 'undefined') score = 0
      if (typeof total === 'undefined') total = 1

      if (total === 0) total = 1

      if (score > total) score = total

      return Math.round(100.0 * score / total)
    },

    calculatePillArrayProgress (pills) {
      var total = 0
      var score = 0

      pills.map(i => {
        total += i.points

        if (i.status === PILL_STATUS.COMPLETE) score += i.points
      })

      return this.calculateProgress(score, total)
    }
  }
}
